 
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
 
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BackgroundBlogCard({ image, title, description, action }) {
    const cardActionStyles = {
        display: "flex",
        alignItems: "center",
        width: "max-content",

        "& .material-icons, .material-icons-round,": {
            transform: `translateX(2px)`,
            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
        },

        "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
        {
            transform: `translateX(6px)`,
        },
    };

    return (
        <Card
           // sx={{
           //     backgroundImage: ({ palette: { black }, functions: { linearGradient, rgba } }) =>
            //        `${linearGradient(rgba(black.main, 0.5), rgba(black.main, 0.5))}, url(${image})`,
            //    backgroundSize: "cover",
           // }}

            sx={{
                backgroundImage: `url(${image})`,
                transform: "scale(0.94)",
                 
               backgroundSize: "cover",
            }}
        >
            <MKBox
                p={3}
            sx={{ mt: 20 ,mb:-5}}//change this for text position
            >
                <MKBox
                    minHeight="20.625rem"
                    my="auto" py={3}>
                    <MKTypography
                        variant="h4"
                        color="white"
                        mb={1}
                        sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                                fontSize: size["3xl"],
                            },
                        })}
                    >
                        {title}
                    </MKTypography>
                    <MKTypography variant="body2" color="white" my={3}>
                        {description}
                    </MKTypography>
              
                </MKBox>
            </MKBox>
        </Card>
    );
}

// Typechecking props for the BackgroundBlogCard
BackgroundBlogCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["external", "internal"]).isRequired,
        route: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

export default BackgroundBlogCard;
