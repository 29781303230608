 
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function CenteredBlogCard({ image 
    //, action
}) {
    return (
        <Card>
            <MKBox position="relative"
                borderRadius="lg"
            // mx={2}
            //mt={-3}
            >
                <MKBox
                    component="img"
                    src={image}
                    // alt={title}
                    borderRadius="lg"
                    width="100%"
                    position="relative"
                    zIndex={1}
                />
                <MKBox
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    height="100%"
                    position="absolute"
                    left={0}
                    top={0}
                    sx={{
                        backgroundImage: `url(${image})`,
                        //  transform: "scale(0.94)",
                        //filter: "blur(12px)",
                        backgroundSize: "cover",
                    }}
                />
            </MKBox>
            {/*<MKBox p={3} mt={-1} textAlign="center">*/}
            {/*    <MKTypography display="inline" variant="h6"*/}
            {/*        //textTransform="capitalize"*/}
            {/*        textTransform="none"*/}
            {/*        fontWeight="regular">*/}
            {/*        {title}*/}
            {/*    </MKTypography>*/}
            {/*    <MKBox mt={1} mb={3}>*/}
            {/*        <MKTypography variant="body2" component="p" color="text">*/}
            {/*            {description}*/}
            {/*        </MKTypography>*/}
            {/*    </MKBox>*/}
            {/*    */}{/*{action.type === "external" ? (*/}
            {/*    */}{/*    <MKButton*/}
            {/*    */}{/*        component={MuiLink}*/}
            {/*    */}{/*        href={action.route}*/}
            {/*    */}{/*        target="_blank"*/}
            {/*    */}{/*        rel="noreferrer"*/}
            {/*    */}{/*        variant="gradient"*/}
            {/*    */}{/*        size="small"*/}
            {/*    */}{/*        color={action.color ? action.color : "dark"}*/}
            {/*    */}{/*    >*/}
            {/*    */}{/*        {action.label}*/}
            {/*    */}{/*    </MKButton>*/}
            {/*    */}{/*) : (*/}
            {/*    */}{/*    <MKButton*/}

            {/*    */}{/*        to={action.route}*/}
            {/*    */}{/*        variant="gradient"*/}
            {/*    */}{/*        size="small"*/}
            {/*    */}{/*        color={action.color ? action.color : "dark"}*/}
            {/*    */}{/*    >*/}
            {/*    */}{/*        {action.label}*/}
            {/*    */}{/*    </MKButton>*/}
            {/*    */}{/*)}*/}
            {/*</MKBox>*/}
        </Card>
    );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
    image: PropTypes.string.isRequired,
  // title: PropTypes.string.isRequired,
 //description: PropTypes.string.isRequired,
    //action: PropTypes.shape({
    //    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    //    route: PropTypes.string.isRequired,
    //    color: PropTypes.oneOf([
    //        "primary",
    //        "secondary",
    //        "info",
    //        "success",
    //        "warning",
    //        "error",
    //        "dark",
    //        "light",
    //    ]),
    //    label: PropTypes.string.isRequired,
    //}).isRequired,
};

export default CenteredBlogCard;
