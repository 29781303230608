import React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ReactGA from 'react-ga4';
//import Icon from '@material-ui/core/Icon';
//import Card from "@material-ui/core/Card";

import Container from "@mui/material/Container";

import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
 
// Images
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

 
import Grid from '@mui/material/Grid';
 
import DicomDetails from "Sections/DicomDetails";
 
import dicom3 from 'Images/Dicom3.jpg';
 
 
 
export default function Dicom() {

 





    return (

        //<>

        //            < MKBox
        //                minHeight="75vh"
        //                width="100%"
        //                sx={{
        //                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
        //                        `${linearGradient(
        //                            rgba(gradients.dark.main, 0.6),
        //                            rgba(gradients.dark.state, 0.6)
        //                        )}, url(${bgImage})`,
        //                    backgroundSize: "cover",
        //                    backgroundPosition: "center",
        //                    display: "grid",
        //                    placeItems: "center",
        //                }}
        //            >
        //                <Container>
        //                    <Grid
        //                        container
        //                        item
        //                        xs={12}
        //                        lg={8}
        //                        justifyContent="center"
        //                        alignItems="center"
        //                        flexDirection="column"
        //                        sx={{ mx: "auto", textAlign: "center" }}
        //                    >
        //                        <MKTypography
        //                            variant="h1"
        //                            color="white"
        //                            sx={({ breakpoints, typography: { size } }) => ({
        //                                [breakpoints.down("md")]: {
        //                                    fontSize: size["3xl"],
        //                                },
        //                            })}
        //                        >
        //                            Why choose OrpleHealth
        //                        </MKTypography>
        //                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
        //                            <b>Our Mission:</b> Empower each individual with easy access to all of their health records and contribute to the progress of clinical science.
        //                        </MKTypography>
        //                <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
        //                            Try Beta
        //                        </MKButton>
        //                        <MKTypography variant="h6" color="white" mt={8} mb={1}>

        //                        </MKTypography>

        //                    </Grid>
        //                </Container>
        //            </MKBox>

        //            <Card
        //                sx={{
        //                    p: 2,
        //                    mx: { xs: 2, lg: 3 },
        //                    mt: -8,
        //                    mb: 4,
        //                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
        //                    backdropFilter: "saturate(200%) blur(30px)",
        //                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
        //                }}
        //            >


        //                <Information />
        //                <Team />
        //                <Testimonial />
        //            </Card>

        //</>

        <>
            < MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${dicom3})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >No more CD/DVDs.  
                            Upload and view Dicom images.Generate Imaging study in FHIR format. 
                        </MKTypography>
                        {/*<MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>*/}
                        {/*    Our platform developed with advanced technologies including proprietary data engine, cloud and AI,  enables you to bring in your health records from a variety of sources : EHR(s), paper documents, radiology CD/DVD and wearable devices.*/}
                        {/*</MKTypography>*/}


                    </Grid>
                </Container>
            </MKBox>

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >

             

                <DicomDetails />
            </Card>

        </>
    );
}