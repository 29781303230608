 
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKAlert from "components/MKAlert";
 

function PatientFeaturing() {
    return (
        <MKBox pt={3} pb={8}   >
            <Container>
                {/*<Grid container spacing={3} sx={{ mb: 12 }}>*/}
                 
                {/*</Grid>*/}
                {/*<Grid container justifyContent="center" sx={{ textAlign: "center" }}>*/}
                  
                {/*</Grid>*/}

                {/*<MKBox component="section" py={6}>*/}
                {/*    <Container>*/}
                <Grid container item xs={12}
                    lg={10} mx="auto"//comment thsi to widen the area
                >
                   
                            <Grid item xs={12}>
                                <MKAlert color="secondary">Health records in Doctor's office or hospital are purged after 7 years. Access all your records anytime, anywhere from one single platform.</MKAlert>
                            </Grid>
                            
                        </Grid>
                {/*    </Container>*/}
                {/*</MKBox>*/}
            </Container>
        </MKBox>


    );
}

export default PatientFeaturing;
