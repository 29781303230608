 
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "../Examples/Cards/DefaultReviewCard";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";
import RotatingCard from "../Examples/Cards/RotatingCard";
import RotatingCardFront from "../Examples/Cards/RotatingCardFront";
import RotatingCardBack from "../Examples/Cards/RotatingCardBack";
import DefaultInfoCard from "../Examples/Cards/DefaultInfoCard";
function Information() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    //lg={6}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <MKTypography variant="h2">Who can benefit from SyncFhir ? </MKTypography>
                    {/*<MKTypography variant="h2" color="info" textGradient mb={2}>*/}
                    {/*    1,679,477+ web developers*/}
                    {/*</MKTypography>*/}
                    {/*<MKTypography variant="body1" color="text" mb={2}>*/}
                    {/*    Many Fortune 500 companies, startups, universities and governmental institutions love*/}
                    {/*    Creative Tim&apos;s products.*/}
                    {/*</MKTypography>*/}
                </Grid>
               

                <Grid container spacing={4} sx={{ mt: 8 }}>
                    <Grid item xs={12}
                        md={6}
                        lg={4}
                    >
                        <RotatingCard>
                            <RotatingCardFront
                                //image={bgFront}
                                //icon="touch_app"
                                title={
                                    <>
                                        Clinical Trials
                                    </>
                                }
                                //description="Fetch, add and upload records. View all the health information through unified health records from any desktop or portable computing device any time, anywhere. Share, download and delete records."
                            />
                            <RotatingCardBack
                                //image={bgBack}
                             //   title="Patients"
                                description="Enhance patient onboarding process. Our Innovative API technology provides precise, patient-sonsented access to electronic health data and helps you streamline the recruitment process."
                              //  action={{
                            //        type: "internal",
                             //       route: "/sections/page-sections/page-headers",
                              //      label: "start with header",
                              //  }}
                            />
                        </RotatingCard>

                    </Grid>
                    <Grid item xs={12}
                        md={6}
                        lg={4}
                    >
                        <RotatingCard>
                            <RotatingCardFront
                                //image={bgFront}
                                //icon="touch_app"
                                title={
                                    <>
                                        RWD platforms
                                    </>
                                }
                              //  description="All the MUI components that you need in a development have been re-design with the new look."
                            />
                            <RotatingCardBack
                                //image={bgBack}
                              //  title="Research"
                                description="Enable dynamic consent process at scale and put study participants in control of their health data."
                            //  action={{
                            //        type: "internal",
                            //       route: "/sections/page-sections/page-headers",
                            //      label: "start with header",
                            //  }}
                            />
                        </RotatingCard>

                    </Grid>
                    <Grid item xs={12}
                        md={6}
                        lg={4}
                    >
                        <RotatingCard>
                            <RotatingCardFront
                                //image={bgFront}
                                //icon="touch_app"
                                title={
                                    <>
                                       Developers & Providers
                                        {/*<br />*/}
                                        {/*Material Kit*/}
                                    </>
                                }
                                //description="Health records from various sources received in structured and unstructired formats are converted to common target FHIR format and made accesible via API."
                            />
                            <RotatingCardBack
                                //image={bgBack}
                             //   title="Developers"
                                description="Easy to use plugin makes your team more efficient.  Get insights into patient's longitudinal health data sourced from other EHR Systems."
                            //  action={{
                            //        type: "internal",
                            //       route: "/sections/page-sections/page-headers",
                            //      label: "start with header",
                            //  }}
                            />
                        </RotatingCard>

                    </Grid>
                </Grid>

                {/*<Divider sx={{ my: 6 }} />*/}
                {/*<Grid container spacing={3} justifyContent="center">*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={appleLogo} alt="Apple" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={nasaLogo} alt="Nasa" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox component="img" src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={4} lg={2}>*/}
                {/*        <MKBox*/}
                {/*            component="img"*/}
                {/*            src={digitalOceanLogo}*/}
                {/*            alt="DigitalOcean"*/}
                {/*            width="100%"*/}
                {/*            opacity={0.6}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Container>
        </MKBox>
    );
}

export default Information;
