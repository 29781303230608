import React from 'react';
 
import Grid from '@mui/material/Grid';
 
import network from 'Images/network.jpg';


import CuratorDetails from "Sections/CuratorDetails";
import PatientTeam from "Sections/PatientTeam";
import Patientfeaturing from "Sections/PatientFeaturing";
import Container from "@mui/material/Container";

import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
 
import green1 from 'Images/green1.jpg';
 
 
export default function Curator() {




    return (

        <>
            < MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${green1})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                           Curate health records. Easy and intutive UI.
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Our AI powered platform lets you bring in unstructured healthrecords in variety of format including pdf, txt,html etc.Extracts health information from it and prepares health records in FHIR format. 
                            Curator platform allows easy correction of records through simple and easy UI.
                        </MKTypography>
                      

                    </Grid>
                </Container>
            </MKBox>

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >

                {/*<MKBox component="section" py={12}>*/}
                {/*    <Container>*/}
                {/*        <Grid container spacing={3} alignItems="center">*/}
                {/*            <Grid item xs={12} lg={6}>*/}
                {/*                <Grid container justifyContent="flex-start">*/}
                {/*                    <Grid item xs={12} md={6}>*/}
                {/*                        <MKBox mb={5}>*/}
                {/*                            <DefaultInfoCard*/}
                {/*                                //icon="public"*/}
                {/*                                title="Patient centric"*/}
                {/*                                description1="Our patient centric platform enables individual patients to bring in longitudinal health data from various sources. Propriety data engine creates unified health records for each individual patient."*/}
                {/*                                description2="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}
                {/*                            />*/}
                {/*                        </MKBox>*/}
                {/*                    </Grid>*/}

                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>*/}
                {/*                <CenteredBlogCard*/}
                {/*                    image={unlockImg}*/}
                {/*                />*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Container>*/}
                {/*</MKBox>*/}

                {/*<Newsletter*/}
                {/*    title="Patient centric"*/}
                {/*    description1="Our patient centric platform enables individual patients to bring in longitudinal health data from various sources. Propriety data engine creates unified health records for each individual patient.OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}
                {/*    />*/}

                {/*<Newsletter*/}
                {/*    title="Advance medical research"*/}
                {/*    description1="Anonymized health records shared  after obtaining  patients' consent contributes to advanced medical research. Research partners collaborate with us to derive true value out of health data and take the clinical research to the next level."*/}
                {/*/>*/}
                {/*<Newsletter*/}
                {/*    title="Developer community"*/}
                {/*    description1="Shared health records are  available via API in FHIR format for third party development apps to consume and harness the power of rich data set."*/}
                {/*/>*/}

                <CuratorDetails />
                {/*<Patientfeaturing/>*/}
                {/*<PatientTeam />*/}
                {/*<Testimonial />*/}
            </Card>

        </>
    );
}