 
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

 
import MKBox from "components/MKBox";

import DefaultInfoCard from "../Examples/Cards/DefaultInfoCard";
import CenteredBlogCard from "../Examples/Cards/CenterBlogCard";
import BackgroundBlogCard from "../Examples/Cards/BackgroundBlogCard";
import unlockImg from '../Images/unlock.jpg';
import bg2 from '../Images/bg2.jpg';
import bg3 from '../Images/bg3.jpg';
function DicomDetails() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}
//                        lg={6}
                    >
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                   // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Dispersed health data"
                                        description="Today a patient's health data is dispersed across many places like EHR/EMR systems, laboratory systems, paper copies in doctor's office, printed documents from health organizations, handwritten scripts, radiology reports, imaging disks (CD/DVD), wearable and IoMT devices etc."
                                    />
                                </MKBox>
                            </Grid>
                           
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Access to electronic health records"
                                        description="A patient cannot assume long term and desired access to data in EHR/EMR systems due to their respective terms and conditions."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Variety of EHRs"
                                        description="Not all care providers use the leading EHR systems. Oftentimes, doctor's office have their own unique electronic record system."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Lab data"
                                        description="Few laboratory systems provide access to results via patient portal. Over the period of several years, a typical patient most likely would have visited multiple laboratory services across various geographical regions. Not all the laboratory data is accessible electronically or via EHR integrations. A portion of laboratory data may come from paper copies."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Traditional record keeping"
                                        description="There is a significant portion of care providers who still work without any EHR/EMR and prefer a traditional way of paper documents."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Reliable long term access"
                                        description="In the United States, federal law requires care providers to maintain medical records for seven years from the date of service after which they may be deleted or made inaccessible."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Radiology data"
                                        description="It is not easy to share radiology images with certain care providers. Unless the care provider has access to the PACs imaging system of the radiology service provider, the only option patient has is to manually deliver the CD/DVD containing radiology images to the doctor's office. In addition to this it is important that a care provider's office should have a computing device equipped with a disk reader then only specific images can be viewed by the provider."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Device generated health records"
                                        description="Data generated by wearable devices and registered IoMT devices  can give valuable insights when connected with clinical health data."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Need for evidence based care"
                                        description="While determining a treatment plan for a patient, all aspects of a patient's health history should be considered including current condition, progression, historical occurrences/treatments, risk factors, effectiveness etc. Instead of following a one solution fits all approach, an evidence based, and customized care plan should be developed."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Access to health records from anywhere"
                                        description="A patient may receive treatments from different geographical locations at different times. Even across different countries in some cases. Individual specific unified health records accessible via portable device and owned by a patient himself/herself will be very valuable."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Unified Health Records"
                                        description="It is important to have a platform which will extract health information from various sources, convert them into a common format, link relevant pieces together and enable users to have access to unified health records specific to an individual."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Complete picture"
                                        description="Currently there are several systems available out there which allow users to ingest and aggregate health data from different sources. These systems address only a portion of health data resources. Also, the data represented through these systems is often in the form of disconnected independent health information pieces. It is difficult to get a clear picture of the entire health of a patient. This implies that in addition to ingestion and aggregation of health data, we need a system which will correctly link all the resources together and display complete health information covering all spectrums of health."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Share records when needed"
                                        description="With patient's consent, deidentified health records can be shared to be part of population health data pool which can be useful for clinical research. When a patient's unified health records are shared with care provider, it can help the care team confirm the diagnosis and develop correct care plan."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Your data, yours to keep"
                                        description="In case a user wishes to exit the system and no longer use it, they would have an ability to export all the unified medical records and the analytics. So that it will be useful for their future use and parents can pass on to future generation their health history in hard copy/ paper format."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Contribute to research"
                                        description="Today, several health conditions still do not have effective treatment or a permanent cure. One of the reasons is a lack of longitudinal population health data. If unified health records for patients covering various life stages such as infants, children and adults are available along with test reports, procedure details, medication history, doctors visit, X-ray images, this can help researchers get valuable insights and progress the research."
                                    />
                                </MKBox>
                            </Grid>
                            {/*<Grid item xs={12}*/}
                            {/*//    md={6}//revert back if alignement does not work. will educe  the column width*/}
                            {/*>*/}
                            {/*    <MKBox*/}
                            {/*    // mb={5}*/}
                            {/*    >*/}
                            {/*        <DefaultInfoCard*/}
                            {/*            //icon="public"*/}
                            {/*            title="Got an upcoming appointment?"*/}
                            {/*            description="Unified health records can be shared with care giver or family member. You decide with whom and for how long."*/}
                            {/*        />*/}
                            {/*    </MKBox>*/}
                            {/*</Grid>*/}
                         
                            
                        </Grid>
                    </Grid>
                
                </Grid>

                {/*<Grid container spacing={3} alignItems="center">*/}
                    
                {/*    <Grid item xs={12} lg={6} >*/}
                {/*        <Grid container justifyContent="flex-start">*/}
                {/*            <Grid item xs={12}*/}
                {/*            //    md={6}//revert back if alignement does not work. will educe  the column width*/}
                {/*            >*/}
                {/*                <MKBox*/}
                {/*                // mb={5}*/}
                {/*                >*/}
                {/*                    <DefaultInfoCard*/}
                {/*                        //icon="public"*/}
                {/*                        title="Too many EHR systems and credentials to remember?"*/}
                {/*                        description=" Just fetch the data from each system once and you're all set. Our platform will enable auto refresh or on demand data fetch based on your preferences."*/}
                {/*                    />*/}
                {/*                </MKBox>*/}
                {/*            </Grid>*/}

                {/*            <Grid item xs={12}*/}
                {/*            //    md={6}//revert back if alignement does not work. will educe  the column width*/}
                {/*            >*/}
                {/*                <MKBox*/}
                {/*                // mb={5}*/}
                {/*                >*/}
                {/*                    <DefaultInfoCard*/}
                {/*                        //icon="public"*/}
                {/*                        title="Want to report daily observations or self treatable health conditions?"*/}
                {/*                        description="We've got you covered."*/}
                {/*                    />*/}
                {/*                </MKBox>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12}*/}
                {/*            //    md={6}//revert back if alignement does not work. will educe  the column width*/}
                {/*            >*/}
                {/*                <MKBox*/}
                {/*                // mb={5}*/}
                {/*                >*/}
                {/*                    <DefaultInfoCard*/}
                {/*                        //icon="public"*/}
                {/*                        title="Did we just create a giant pool of disconnected pieces of information? "*/}
                {/*                        description="Yes. We need to carefully connect all these pieces to derive a meaningful usage out of them. Our proprietary data engine processes health records received from a variety of sources in a variety of formats and generates unified health records for you. So that next time you want to access or share any health information, it will be just a piece of cake!"*/}
                {/*                    />*/}
                {/*                </MKBox>*/}
                {/*            </Grid>*/}

                {/*            <Grid item xs={12}*/}
                {/*            //    md={6}//revert back if alignement does not work. will educe  the column width*/}
                {/*            >*/}
                {/*                <MKBox*/}
                {/*                // mb={5}*/}
                {/*                >*/}
                {/*                    <DefaultInfoCard*/}
                {/*                        //icon="public"*/}
                {/*                        title="Want to contribute to science? "*/}
                {/*                        description="With patient's consent, their anonymized health records can be shared for advanced clinical research."*/}
                {/*                    />*/}
                {/*                </MKBox>*/}
                {/*            </Grid>*/}

                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }} >*/}
                {/*        */}{/*<CenteredBlogCard*/}
                {/*        */}{/*     image={bg2}*/}
                {/*        */}{/*    //image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"*/}
                {/*        */}{/*    //title="Unlock the true potential of health data "*/}
                {/*        */}{/*    //description="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."*/}

                {/*        */}{/*/>*/}
                {/*        <BackgroundBlogCard*/}
                {/*            image={bg2}*/}
                {/*            title="You choose who can access the records."*/}
                {/*            description="Unified health records can be shared with care giver(s) or family member(s)."*/}
                            
                {/*        />*/}
                    
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Container>
        </MKBox>
    );
}

export default DicomDetails;
