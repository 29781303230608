import React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ReactGA from 'react-ga4';
//import Icon from '@material-ui/core/Icon';
//import Card from "@material-ui/core/Card";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";



// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Images
//import bgImage from "assets/images/illustrations/illustration-reset.jpg";
//import bgImage from "Images/contact-edited-2.png";
import bgImage from "Images/wait.png";



import MKAlert from "components/MKAlert";

import Grid from '@mui/material/Grid';
import { EmailClient } from "@azure/communication-email";
import MKInput from "components/MKInput";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function JoinWaitlist() {
    const [showEmailSent, setShowEmailsent] = useState(false);
    const [message, setMessage] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [senderName, setSenderName] = useState('');
    const connectionString = process.env.REACT_APP_COMMUNICATION_SERVICES_CONNECTION_STRING;
    const toEmail = process.env.REACT_APP_TO_EMAIL;
    const fromAddress = process.env.REACT_APP_FROM_EMAIL;
    const [openBackDrop, setOpenBackDrop] = useState(false);
    //const { EmailClient } = require("@azure/communication-email");
    const client = new EmailClient(connectionString);

    async function handleSendButtonClick(e) {
        //const emailMessage = {
        //    //senderAddress: toEmail,
        //    senderAddress: "nishivr@yahoo.com",
        //                content: {
        //        subject: "Test Email",
        //        plainText: "Hello world via email.",
        //    },
        //    recipients: {
        //        to: [{ address: toEmail }],
        //    },
        //};
        setOpenBackDrop(true);
        let strMsg = 'This is the body';


        let strSenderEmail = '';
        if (senderEmail != '') {

            strMsg = 'User joined the waitlist  : ' + senderEmail;


            const emailMessage = {
                senderAddress: fromAddress,
                content: {
                    subject: "Beta waitlist",
                    plainText: strMsg,
                },
                recipients: {
                    to: [
                        {
                            address: toEmail,
                            displayName: "Customer Name",
                        },
                    ],
                },
            };



            const poller = await client.beginSend(emailMessage);
            const result = await poller.pollUntilDone();
            setShowEmailsent(true);
            setOpenBackDrop(false);
        }
    }



    return (



        <Grid container sx={{
            bgcolor:
                //'#F0EFEF'
                '#42424A'
        }}
            //spacing={3}
            spacing={3}
            alignItems="center">
            <Grid item xs={12} lg={6}>
                <MKBox
                    display={{ xs: "none", lg: "flex" }}
                    width="calc(100% - 2rem)"
                    height="calc(100vh - 2rem)"
                    //height="calc(100vh)"
                    borderRadius="lg"
                    ml={2}
                    mt={5}
                    sx={{ backgroundImage: `url(${bgImage})` }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={10}
                md={7}
                lg={6}
                xl={4}
                ml={{ xs: "auto", lg: 6 }}
                mr={{ xs: "auto", lg: 6 }}
            >
                {/*<MKBox*/}
                {/*    bgColor="white"*/}
                {/*    borderRadius="xl"*/}
                {/*    shadow="lg"*/}
                {/*    display="flex"*/}
                {/*    flexDirection="column"*/}
                {/*    justifyContent="center"*/}
                {/*    mt={{ xs: 20, sm: 18, md: 20 }}*/}
                {/*    mb={{ xs: 20, sm: 18, md: 20 }}*/}
                {/*    mx={3}*/}
                {/*>*/}
                {/*    <MKBox*/}
                {/*        variant="gradient"*/}
                {/*        bgColor="info"*/}
                {/*        coloredShadow="info"*/}
                {/*        borderRadius="lg"*/}
                {/*        p={2}*/}
                {/*        mx={2}*/}
                {/*        mt={-3}*/}
                {/*    >*/}
                {/*        <MKTypography variant="h3" color="white">*/}
                {/*            Contact us*/}
                {/*        </MKTypography>*/}
                {/*    </MKBox>*/}
                {/*    <MKBox p={3}>*/}
                {/*        <MKTypography variant="body2" color="text" mb={3}>*/}
                {/*            For further questions, including partnership opportunities, please contact using our contact form.*/}
                {/*        </MKTypography>*/}
                {/*        <MKBox width="100%" component="form" method="post" autoComplete="off">*/}
                {/*            <Grid container spacing={3}>*/}
                {/*                <Grid item xs={12} md={6}>*/}
                {/*                    <MKInput*/}
                {/*                        variant="standard"*/}
                {/*                        label="Full Name"*/}
                {/*                        InputLabelProps={{ shrink: true }}*/}
                {/*                        fullWidth*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={12} md={6}>*/}
                {/*                    <MKInput*/}
                {/*                        type="email"*/}
                {/*                        variant="standard"*/}
                {/*                        label="Email"*/}
                {/*                        InputLabelProps={{ shrink: true }}*/}
                {/*                        fullWidth*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <MKInput*/}
                {/*                        variant="standard"*/}
                {/*                        label="how can we help you?"*/}
                {/*                        placeholder="Our Team will get back to you within 24 hours."*/}
                {/*                        InputLabelProps={{ shrink: true }}*/}
                {/*                        multiline*/}
                {/*                        fullWidth*/}
                {/*                        rows={6}*/}
                {/*                    />*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>*/}
                {/*                <MKButton type="submit" variant="gradient" color="info">*/}
                {/*                    Send Message*/}
                {/*                </MKButton>*/}
                {/*            </Grid>*/}
                {/*        </MKBox>*/}
                {/*    </MKBox>*/}
                {/*</MKBox>*/}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openBackDrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Card
                    sx={{
                        p: 2,
                        mx: { xs: 2, lg: 3 },
                        mt: 5,
                        mb: 4,
                        //backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                        backdropFilter: "saturate(200%) blur(30px)",
                        boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    }}
                >
                    <MKBox component="form" p={2} method="post">

                        <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                            {(!showEmailSent) &&
                                <MKTypography variant="h2" mb={1}>
                                    We're almost there!
                                </MKTypography>
                            }
                            {(!showEmailSent) &&
                                <MKTypography variant="body1" color="text" mb={2}>
                                    Provide your email to join beta waitlist.
                                </MKTypography>
                            }
                        </MKBox>

                        <MKBox pt={0.5} pb={3} px={3}>
                            <Grid container>

                                {(showEmailSent) &&
                                    <Grid item xs={12}>
                                        <MKAlert color="success">Congratulations! You'll be notified soon. Stay tuned.</MKAlert>
                                    </Grid>
                                }

                                <Grid item xs={12} pr={1} mb={6}>
                                    {(!showEmailSent) &&
                                        <MKInput
                                            variant="standard"
                                            label="Email"
                                            // placeholder="Email"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => {
                                                setSenderEmail(e.target.value);
                                            }}
                                            fullWidth
                                        />
                                    }
                                </Grid>

                            </Grid>

                            <Grid
                                container
                                item
                                xs={12}
                                md={6}
                                justifyContent="flex-end"
                                textAlign="right"
                                ml="auto"
                            >
                                {(!showEmailSent) &&
                                    <MKButton variant="gradient" color="info" onClick={(e) => handleSendButtonClick(e)}>
                                        Join
                                    </MKButton>
                                }
                            </Grid>

                        </MKBox>
                    </MKBox>
                </Card>
            </Grid>
        </Grid>

    );
}