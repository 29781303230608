
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


import MKBox from "components/MKBox";

import DefaultInfoCard from "../Examples/Cards/DefaultInfoCard";
import CenteredBlogCard from "../Examples/Cards/CenterBlogCard";
import BackgroundBlogCard from "../Examples/Cards/BackgroundBlogCard";
import unlockImg from '../Images/unlock.jpg';
import bg9 from '../Images/blog9.jpg';
import familylook from 'Images/family-look.jpg';

function Information() {
    return (
        <MKBox
            component="section" py={12}
        >
            <Container>

                {/*<Grid container  alignItems="left">*/}
                {/*    <Grid item xs={12}  >*/}
                {/*        <div style={{*/}
                {/*        border: 'solid black 10px', */}
                {/*            padding: '20px',*/}
                {/*            //borderRadius:'40px',*/}
                {/*        //    max- width: 400px; margin: 0 auto;"*/}
                {/*        }}>*/}
                {/*            <img src="https://syncfhirsitestorage.blob.core.windows.net/concept/Syncfhir video 2.gif" loading="lazy">*/}
                {/*            </img>*/}
                {/*        </div>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Quick and easy"
                                        description="Simple component to include in your existing application. Free up your team to focus on more important stuff- building your applciation."
                                    />
                                </MKBox>
                            </Grid>

                            <Grid item xs={12}
                            //    md={6}//revert back if alignement does not work. will educe  the column width
                            >
                                <MKBox
                                // mb={5}
                                >
                                    <DefaultInfoCard
                                        //icon="public"
                                        title="Scale up fast"
                                        description="Connect with hundreds of EHR systems. No more complicated integrations."
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            //md={6}
                            >
                                <MKBox mb={{ xs: 5, md: 0 }}>
                                    <DefaultInfoCard
                                        //     icon="apps"
                                        title="Put patients in control of their health data "
                                        description="Only patient-consented health records. We never store patient health data. "
                                    />
                                </MKBox>
                            </Grid>
                            <Grid item xs={12}
                            // md={6}
                            >
                                <MKBox mb={{ xs: 5, md: 0 }}>
                                    <DefaultInfoCard
                                        //     icon="3p"
                                        title="Gain insights into patient health history"
                                        description="With very minimal configuration required,  access to patiens'  longitudinal health data in seconds."
                                    />
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}
                        lg={5}
                        sx={{
                            ml: "auto",
                            //ml: "30",
                            mt: { xs: 3, lg: 0 },
                           //pl: '50',
                        }
                        }
                    >
                        <CenteredBlogCard
                            //image={familylook}
                            image="https://syncfhirsitestorage.blob.core.windows.net/concept/Syncfhir video 2.gif"
                        // image={bg9}
                        // image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                        // title="Unlock the true potential of health data "
                        //description="OrpleHealth platform generates secure, compliant, and always available population health data pool. Get valuable insights from your own health records."

                        />



                    </Grid>

                    {/*<Grid item xs={12} lg={6}  >*/}
                    {/*    <div style={{*/}
                    {/*        border: 'solid black 10px',*/}
                    {/*        //padding: '5px',*/}
                    {/*        marginLeft:"30px",*/}
                    {/*        //borderRadius:'40px',*/}
                    {/*        //    max- width: 400px; margin: 0 auto;"*/}
                    {/*        //width:"80%"*/}
                    {/*    }}>*/}
                    {/*        <img src="https://syncfhirsitestorage.blob.core.windows.net/concept/Syncfhir video 2.gif" loading="lazy"*/}

                    {/*            style={{*/}
                    {/*                //border: 'solid red 10px',*/}
                                  
                    {/*                //borderRadius:'50px',*/}
                    {/*                //    max- width: 400px; margin: 0 auto;"*/}
                    {/*                //width: "80%"*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*        </img>*/}
                    {/*    </div>*/}
                    {/*</Grid>*/}

                </Grid>
            </Container>
        </MKBox>
    );
}

export default Information;
